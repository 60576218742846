import React from 'react';
import Manvita from "../assets/Manvita.jpg";
import Arun from "../assets/Arun.png";
import Sowmya from "../assets/Sowmya.jpg";

const TeamIntroduction = () => {
  const teamMembers = [
    {
      name: "Manvita Kamath",
      role: "Founder",
      description: "Manvita Kamath is an Indian actress based out of Bengaluru. She has won the prestigious Filmfare and SIIMA awards for her performances. She graduated in Journalism, Animation and English Literature from St. Aloysius, Mangalore and also did her Master's in Mass Media and International Studies at Jain CMS College, Bangalore, enabling her to have a strong foundation in branding and marketing.",
      image: Manvita
    },
    {
      name: "Arun Kumar",
      role: "Co Founder",
      description: "Arun is an engineer from Mysore who has more than a decade of experience building mobile and web applications for various companies including Myntra, Vedantu and Beatoven.ai. He loves music of all genres and is also a music producer who enjoys making music using hardware synthesisers, samplers and drum machines. He'll be the main tech person for building the website and driving the social media for the brand",
      image: Arun
    },
    {
      name: "Sowmya",
      role: "Creative Director",
      description: "Sowmya is a Fashion Designer who graduated in Master's in Fashion from Istituto Marangoni, Milan. She has worked for projects like wookmarkk (Australia) and Tom Rebl (Italy) and interned at Milan Fashion Week. Her work journey started with international brand like Rahul Mishra and House of Three, and has done a lot of freelance projects for the movie industry.",
      image: Sowmya
    }
  ];

  return (
    <div className="container mx-auto  w-full">
      <h1 className="text-2xl mb-4 mt-8 text-center underline font-light tracking-wider font-Qochy text-4xl font-normal">Founding Team</h1>
      
      <div className="flex flex-col w-4/6 mobile:w-full">
        {teamMembers.map((member, index) => (
          <div key={index} className="p-4">
            <img 
            src={member.image} alt={member.name}
            className="float-left mr-4 mb-4 w-48 h-auto object-contain mobile:w-44"
            />
            {/* <div className="w-1/6 rounded-full mobile:w-2/6">
                <img src={member.image} alt={member.name} className={"w-full h-auto"}  />
            </div> */}
            <div className="space-y-4 ml-4">
              <div>
                <h2 className="text-2xl font-medium font-Optima">{member.name}</h2>
                <p className="text-lg text-gray-600 italic text-gray-600 font-Optima font-normal">{member.role}</p>
              </div>
              
              <p className="text-gray-700 leading-relaxed font-Optima font-normal text-base">
                {member.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default TeamIntroduction;