import React, { useContext, useEffect, useRef, useState } from "react";
import mainImg from "../assets/mainimg1.png";
import img1 from "../assets/img1.png";
import img2 from "../assets/img2.png";
import img3 from "../assets/img3.png";
import img4 from "../assets/img4.png";
import img5 from "../assets/img5.png";
import { AuthContext } from "../context/AuthContext";
import mixpanel from "mixpanel-browser";
import { Link } from "react-router-dom";

const HomePage = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isVisible1, setIsVisible1] = useState(false);
  const [isVisible2, setIsVisible2] = useState(false);
  const { user, setUser } = useContext(AuthContext);

  // for text animation
  const divRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
      const rect = divRef.current.getBoundingClientRect();
      if (rect.top <= window.innerHeight && rect.bottom >= 0) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleLinkClick = (section) => {
    mixpanel.track('Section Clicked', {
        section: section
    });
  };

  // for middle images
  const leftDivRef = useRef(null);
  const rightDivRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const leftDivRect = leftDivRef.current.getBoundingClientRect();
      const rightDivRect = rightDivRef.current.getBoundingClientRect();
      if (
        (leftDivRect.top <= window.innerHeight && leftDivRect.bottom >= 0) ||
        (rightDivRect.top <= window.innerHeight && rightDivRect.bottom >= 0)
      ) {
        setIsVisible1(true);
      } else {
        setIsVisible1(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // for bottom images
  const leftDivRef1 = useRef(null);
  const rightDivRef1 = useRef(null);

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const leftDivRect = leftDivRef1.current.getBoundingClientRect();
  //     const rightDivRect = rightDivRef1.current.getBoundingClientRect();
  //     if (
  //       (leftDivRect.top <= window.innerHeight && leftDivRect.bottom >= 0) ||
  //       (rightDivRect.top <= window.innerHeight && rightDivRect.bottom >= 0)
  //     ) {
  //       setIsVisible2(true);
  //     } else {
  //       setIsVisible2(false);
  //     }
  //   };

  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <section className="h-full w-full overflow-x-hidden">
      {/* main */}
      <div className="w-full h-full relative">
        <img
          src={mainImg}
          alt="mainimg"
          className="w-full h-auto object-cover"
          width="100%"
          height="100%"
        />
        <div
          ref={divRef}
          className={`absolute left-20 top-1/2 text-white p-4 flex flex-col items-start mobile:left-0 sm:left-0 ${
            isVisible ? "visible-from-bottom" : "hidden-from-bottom"
          }`}
        >
          <p className="font-Optima font-normal text-xl lg:text-2xl text-white">
            Luxury is here!
          </p>
          <p className="font-Qochy font-normal text-7xl text-white mobile:text-2xl">
            Discover the power of silk
          </p>
          <div className="mt-2 text-white underline uppercase font-Optima text-lg mobile:text-sm tracking-wider">
            <Link to="/shop" onClick={() => handleLinkClick('Section 1')}>Shop Now</Link>
          </div>
          {/* <button className="mt-2 text-white underline font-Optima text-lg">
            Shop now
          </button> */}
        </div>
      </div>
      {/* middle2 */}
      <div className="w-full h-full py-12 mobile:py-6">
        <div>
          <p className="text-center font-normal text-2xl font-Optima mb-4 mobile:mb-1 mobile:text-sm">
            Journey into Sophistication
          </p>
          <div className="text-center font-Qochy font-normal mobile:text-xl text-4xl">
            Traditional fabric meets international silhouettes
          </div>
          <div className="mt-10 mobile:mt-4 flex w-full h-full gap-8 mobile:flex-col">
            <div
              ref={leftDivRef}
              className={`w-2/5 relative mobile:w-full transform transition-transform duration-700 ease-out ${
                isVisible1 ? "translate-x-0" : "-translate-x-full"
              }`}
            >
              <img
                src={img1}
                alt="img1"
                className="w-full h-full"
                width="100%"
                height="100%"
              />
              <div className="absolute bottom-28 left-10 mobile:left-2 mobile:bottom-8">
                <p className="font-normal text-xl lg:text-3xl font-Optima text-white">
                  Atra Silk Gold Print Blazer set
                </p>
                <div className="font-normal text-base font-Optima text-white underline uppercase mobile:text-xs tracking-wider">
                  <Link to="/shop/atra-silk-brazer" onClick={() => handleLinkClick('Atra Silk Gold Print Blazer set')}>Explore</Link>
                </div>
              </div>
            </div>
            <div
              ref={rightDivRef}
              className={`w-3/5 relative mobile:w-full transform transition-transform duration-700 ease-out ${
                isVisible1 ? "translate-x-0" : "translate-x-full"
              }`}
            >
              <img src={img2} alt="img2" className="w-full h-full" />
              <div className="absolute bottom-28 left-10 mobile:left-2 mobile:bottom-8">
                <p className="font-normal  text-xl lg:text-3xl font-Optima text-white">
                 Kesa Silk Brocade Blazer
                </p>
                <div className="font-normal text-base font-Optima text-white underline uppercase mobile:text-xs tracking-wider">
                  <Link to="shop/kesa-silk-brocade-blazer" onClick={() => handleLinkClick('Kesa Silk Brocade Blazer')}>Explore</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full h-full py-12 mobile:py-4">
        <div>
          {/* <p className="text-center font-normal lg:text-2xl font-Optima mb-4 sm:text-lg">
            Journey into Sophistication,
          </p> */}
          <div className="text-center font-Qochy font-normal mobile:text-xl text-4xl">
            Sneakpeek to our Lookbooks
          </div>
          <div className="mt-10 mobile:mt-4 flex w-full h-full gap-8 mobile:flex-col">
            <div className="w-2/5 h-full relative mobile:w-full">
              <img
                src={img3}
                alt="img3"
                className="w-full h-full object-cover "
                width="100%"
                height="100%"
              />
              <div className="absolute bottom-28 left-10 mobile:left-2 mobile:bottom-8">
                <p className="font-normal text-xl lg:text-3xl font-Optima text-white">
                  Atra Silk Gold Print Blazer set
                </p>
                <div className="font-normal text-base font-Optima text-white underline uppercase mobile:text-xs tracking-wider">
                  <Link to="/shop/atra-silk-brazer" onClick={() => handleLinkClick('Look 1 - Atra Silk Gold Print Blazer set')}>Explore</Link>
                </div>
              </div>
            </div>
            <div className="w-2/5 h-full relative mobile:w-full">
              <img
                src={img4}
                alt="img4"
                className="w-full h-full object-cover"
                width="100%"
                height="100%"
              />
              <div className="absolute bottom-28 left-10 mobile:left-2 mobile:bottom-8">
                <p className="font-normal text-xl lg:text-3xl font-Optima text-white">
                  Parna Satin Silk Trouser with Blazer
                </p>
                <div className="font-normal text-base font-Optima text-white underline uppercase mobile:text-xs tracking-wider">
                  <Link to="/shop/parna-satin-silk-trousers-with-blazer" onClick={() => handleLinkClick('Look 2 -  Parna Satin Silk Trouser with Blazer')}>Explore</Link>
                </div>
              </div>
            </div>
            <div className="w-2/5 h-full relative mobile:w-full">
              <img
                src={img5}
                alt="img4"
                className="w-full h-full object-cover"
                width="100%"
                height="100%"
              />
              <div className="absolute bottom-28 left-10 mobile:left-2 mobile:bottom-8">
                <p className="font-normal text-xl lg:text-3xl font-Optima text-white">
                Kuva Silk Lurex blazer Look
                </p>
                <div className="font-normal text-base font-Optima text-white underline uppercase mobile:text-xs tracking-wider">
                  <Link to="/shop/kuva-silk-lurex-blazer-look" onClick={() => handleLinkClick('Look 3 -  Kuva Silk Lurex blazer Look')}>Explore</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* bottom */}
      {/* <div className="w-full h-full py-12">
        <div>
          <p className="text-center font-normal text-2xl font-Optima mb-4">
            Get inspired
          </p>
          <div className="text-center font-Qochy font-normal text-5xl">
            Follow us @ManekinLuxOfficals
          </div>
          <div className="mt-10 flex w-full h-full gap-8 mobile:flex-col">
            <div
              className={`w-full h-full relative transform transition-transform duration-700 ease-out ${
                isVisible2 ? "translate-x-0" : "-translate-x-full"
              }`}
              ref={leftDivRef1}
            >
              <img
                src={img5}
                alt="img5"
                className="w-full h-full object-contain object-center"
              />
            </div>
            <div className="flex flex-col gap-3 w-full h-full">
              <img
                src={img6}
                alt="img6"
                className="w-full h-full object-contain object-center"
              />
              <img
                src={img7}
                alt="img7"
                className="w-full h-full object-contain object-center"
              />
            </div>
            <div
              className={`w-full h-full relative transform transition-transform duration-700 ease-out ${
                isVisible2 ? "translate-x-0" : "translate-x-full"
              }`}
              ref={rightDivRef1}
            >
              <img
                src={img8}
                alt="img8"
                className="w-full h-full object-contain object-center"
              />
            </div>
          </div>
        </div>
      </div> */}
    </section>
  );
};

export default HomePage;
